import StandardNavigation from "@components/navigation/StandardNavigation";
import Image from "next/image";
import { Box, Container, Typography, useTheme, makeStyles, Button } from "@material-ui/core";
import theme from "@styles/theme";
import FeatureCard from "@components/index/components/FeatureCard";
import LinkToPage from "@components/navigation/components/LinkToPage";
import Link from "next/link";

const useStyles = makeStyles({
  grid: {
    gap: theme.spacing(2),
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  expertGrid: {
    gap: theme.spacing(6),
    width: "100%",
    display: "grid",
    gridTemplateColumns: "1fr 3fr",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
});

const Colored = (props: { children: React.ReactChild | React.ReactChild[] }) => {
  return <span style={{ color: theme.palette.primary.main }}>{props.children}</span>;
};

export default function Home() {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <StandardNavigation>
      <Box py={6} style={{ backgroundColor: theme.palette.background.default }}>
        <Container
          maxWidth="lg"
          style={{
            width: "100%",
            textAlign: "center",
          }}
        >
          <Box pb={6}>
            <Image src="/images/report_analysis.svg" width={250} height={250} />
          </Box>
          <Box pb={6}>
            <Typography variant="h2" style={{ fontWeight: 600, color: theme.palette.text.primary }}>
              Transform <Colored>data </Colored> into beautifully designed{" "}
              <Colored>documents </Colored> that you can use <Colored>anywhere</Colored>.
            </Typography>
          </Box>

          <div className={classes.grid}>
            <FeatureCard
              icon={"📥"}
              title={"Upload your template"}
              description={
                "Start from a template made in your favorite text editor such as Google docs or Microsoft word."
              }
            />
            <FeatureCard
              icon={"⚡️"}
              title={"Generate an API endpoint"}
              description={
                "Gain access to an API endpoint that can export your template in PDF, word, google docs or html format."
              }
            />
            <FeatureCard
              icon={"🤖"}
              title={"Merge your data"}
              description={"Send your data to the API endpoint to create personalised documents."}
            />
          </div>
        </Container>
      </Box>

      <Box
        my={5}
        style={{
          backgroundColor: theme.palette.secondary.main,
          minHeight: "400px",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Box py={10}>
            <Typography
              variant="h3"
              style={{
                textAlign: "center",
                fontWeight: 600,
                color: "white",
                marginBottom: theme.spacing(6),
              }}
            >
              Checkout our quickstart or jump into the API playground
            </Typography>

            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <Link href="/getting-started/quickstart" passHref>
                <Button color="primary" variant="contained" fullWidth style={{ maxWidth: "250px" }}>
                  Quickstart
                </Button>
              </Link>

              <div style={{ width: theme.spacing(2) }}></div>
              <Link href="/api-playground" passHref>
                <Button color="primary" variant="contained" fullWidth style={{ maxWidth: "250px" }}>
                  API Playground
                </Button>
              </Link>
            </div>
          </Box>
        </Container>
      </Box>
      <Container>
        <Box py={10} className={classes.expertGrid}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Image
              src="/images/expert_engine.png"
              width={1345 * 0.25}
              height={803 * 0.25}
              layout="intrinsic"
            />
          </div>
          <div style={{ justifyContent: "center" }}>
            <Typography
              gutterBottom
              variant="body1"
              style={{ fontWeight: 500, color: theme.palette.text.primary, fontSize: 20 }}
            >
              Aeco Docs is part of the <Colored>expert engine,</Colored> a collection of
              technologies{" "}
              <Colored>
                that help legal and tax companies accelerate their digital offering.
              </Colored>
            </Typography>
            <Typography
              variant="body1"
              style={{ fontWeight: 500, color: theme.palette.text.primary, fontSize: 20 }}
            ></Typography>
            <Box py={3}>
              {" "}
              <LinkToPage url="https://www.aeco.cloud">
                {" "}
                <Button color="primary" variant="contained">
                  Visit the Aeco website
                </Button>
              </LinkToPage>
            </Box>
          </div>
        </Box>
      </Container>
    </StandardNavigation>
  );
}
